<template>
  <div class="d-flex justify-content-center mt-5">
    <b-spinner class="mr-1" />
    {{ $t('Loading') }}
  </div>
</template>

<script>
import {
  BSpinner,
} from 'bootstrap-vue'
import UserManager from '@/services/auth.service'

const auth = new UserManager()

auth.signIn()

export default {
  components: {
    BSpinner,
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
